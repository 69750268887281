import React from 'react';
import './Page-intro.scss';
import usePlaying from "./hooks/usePlaying";

const PageIntro = ({soundfile ,children}) => {

    const {isPlaying, setPlaying} = usePlaying({audio: soundfile} );

    return (
        <section className="page-intro">
                <div onClick={() => setPlaying(!isPlaying)} className="control-wrapper">
                    <svg   width="47" height="49" viewBox="0 0 47 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {!isPlaying && <>
                        <path  d="M46.1098 30.2024C45.6259 21.5428 46.3135 9.16453 36.737 3.15371C33.2222 0.963327 27.1604 -0.259213 22.6777 0.0464216C19.7742 0.224709 15.317 1.09067 12.6173 1.85476C5.63859 3.86686 2.12379 11.5332 0.875781 16.2451C-1.18726 23.9878 0.646555 32.1126 3.75384 39.2441C6.12252 44.7201 10.0194 46.2228 17.2782 47.7255C22.3466 48.7697 28.3065 49.8649 33.4259 47.9547C41.3215 45.0002 46.5173 38.1744 46.3645 31.807L46.1098 30.2024Z" fill="#A5C74C"/>
                        <path  d="M8.13454 17.3912C6.75918 23.7586 4.87443 33.2587 10.6306 39.1931C12.7445 41.3835 15.7245 42.1731 19.0355 42.6825C21.175 43.0136 24.5115 44.2616 26.6255 44.1597C32.076 43.8541 36.3548 38.1744 38.2141 34.9652C41.245 29.693 41.5506 23.5039 40.7101 17.7987C40.0479 13.4179 37.5264 11.7115 32.5599 9.4192C29.096 7.84008 24.9954 6.05721 20.8948 6.61754C14.5783 7.45804 9.43349 11.5586 8.31283 16.1941L8.13454 17.3912Z" fill="white"/>
                        <path  d="M16.616 9.57202V39.3714L38.7745 24.8538L16.616 9.57202Z" fill="#59BA58"/>
                        </>
                        }
                        {isPlaying && <>
                            <path  d="M46.1098 30.2024C45.6259 21.5428 46.3135 9.16453 36.737 3.15371C33.2222 0.963327 27.1604 -0.259213 22.6777 0.0464216C19.7742 0.224709 15.317 1.09067 12.6173 1.85476C5.63859 3.86686 2.12379 11.5332 0.875781 16.2451C-1.18726 23.9878 0.646555 32.1126 3.75384 39.2441C6.12252 44.7201 10.0194 46.2228 17.2782 47.7255C22.3466 48.7697 28.3065 49.8649 33.4259 47.9547C41.3215 45.0002 46.5173 38.1744 46.3645 31.807L46.1098 30.2024Z" fill="#bbd37c"/>
                            <path  d="M8.13454 17.3912C6.75918 23.7586 4.87443 33.2587 10.6306 39.1931C12.7445 41.3835 15.7245 42.1731 19.0355 42.6825C21.175 43.0136 24.5115 44.2616 26.6255 44.1597C32.076 43.8541 36.3548 38.1744 38.2141 34.9652C41.245 29.693 41.5506 23.5039 40.7101 17.7987C40.0479 13.4179 37.5264 11.7115 32.5599 9.4192C29.096 7.84008 24.9954 6.05721 20.8948 6.61754C14.5783 7.45804 9.43349 11.5586 8.31283 16.1941L8.13454 17.3912Z" fill="white"/>
                            <path d="M15 11L22.8486 11.1192L23 35L15.2999 34.515L15 11Z" fill="#5BBA4C"/>
                            <path d="M26 11L33.8486 11.1192L34 35L26.3029 34.515L26 11Z" fill="#5BBA4C"/>
                        </>
                        }
                    </svg>

                </div>
            <div className="intro-content">
                {children}
            </div>
        </section>
    )
}

export default PageIntro
