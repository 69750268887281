import React from 'react';
import {Link} from "react-router-dom";
import {ReactComponent as BackButtonIcon} from "../../images/backbutton.svg";
import {ReactComponent as BackButtonIconDark} from "../../images/backbutton-dark.svg";

const Backbutton = ({linkUrl,title,color}) => {
    let buttonColor;
    if (color === 'dark'){
        buttonColor = <BackButtonIconDark/>;
    }else{
        buttonColor = <BackButtonIcon/>;
    }
    return (
        <div className="backbutton">
            <Link to={linkUrl}>
                {buttonColor}
                 <span>{title}</span>
            </Link>
        </div>
    )
}



export default Backbutton
