import React from 'react';
import ReactDOM from 'react-dom';
import createStore from './app/store';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const store = createStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App/>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

serviceWorkerRegistration.register();

