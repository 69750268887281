import React from 'react';
import './Learning-mindset.scss';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import SubNav from "../page-content/Sub-nav"
import {ReactComponent as LaerendeTankesett} from "../../images/categories/laerende-tankesett.svg";
import {learningMindset} from "../../content";

const LearningMindset = () => (
    <section className="learning-mindset page">
        <div className="content-wrapper">
            <Backbutton linkUrl="/valg" title="Forsiden"/>
            <div className="top-image-wrapper">
                <LaerendeTankesett />
            </div>
            <PageIntro soundfile={learningMindset.pageIntroAudio}>
                <p>{learningMindset.pageIntro}</p>
            </PageIntro>
            <SubNav navItems={learningMindset.navText}/>
        </div>

    </section>
)

export default LearningMindset;
