import React from 'react';

import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"


import TextList from "../page-content/Text-list";
import './Care-list.scss'
import {careList} from "../../content";
import ListenToText from "../page-content/Listen-to-text";




const CareList = () => {



    return (
        <section className="care-list page">
            <div className="content-wrapper">
                <Backbutton color="dark" linkUrl="/sosiale-relasjoner" title="Sosiale relasjoner"    />
                <h1>{careList.title}</h1>
                <PageIntro soundfile={careList.pageIntroAudio}>
                    <p>{careList.pageIntro}</p>
                </PageIntro>
                <div className="intermission-image">
                    <img src={careList.image} alt=""/>
                </div>
                <ListenToText svgColor={"dark"} soundfile={careList.listAudio}/>
                <TextList listItems={careList.list}/>
            </div>
        </section>
    )
}

export default CareList
