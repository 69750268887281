import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import './Home.scss'

import {ReactComponent as Logo} from "./../../images/logos/robust-logo.svg";
import forskningsraadet from "./../../images/logos/forskningsraadet.png";
import laeringsmiljosentet from "./../../images/logos/laeringsmiljosenteret.png";
import {frontpage} from '../../content';
import {passAuth} from "../../content";

const Home = () => {
    const [phrase, setPhrase] = useState("");
    const history = useHistory()
    const [isLoginError, setIsLoginError] = useState(false);
    const [data, setData] = useState(localStorage.getItem("authorized") || '');

    useEffect(() => {
        if (data === 'yes') {
            history.push('/valg');
        }
    }, [data, history])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (phrase?.toLowerCase() === passAuth.phrase) {
            setIsLoginError(false);
            setData('yes');
            localStorage.setItem("authorized", 'yes')
            history.push('/valg');
        } else {
            setIsLoginError(true);
        }
    }

    return (
        <section className="home page">
            <div className="content-wrapper">
                <div id="logo">
                    <Logo/>
                </div>

                <div className="videoWrapper">
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/451397088" width="640" height="360"
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className="texWrapper">
                    <p>{frontpage.textContent[0]}</p>
                    <p>{frontpage.textContent[1]}</p>
                </div>
                <form onSubmit={handleSubmit} className="login-form">
                    <p>Passord</p>
                    <div className={`input-wrapper` + (isLoginError ? ' login-error' : '')}>
                        <svg width="292" height="64" viewBox="0 0 292 64" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M83.1233 60.7653C143.144 63.2179 210.39 61.9601 248.83 57.4952C271.567 54.8539 280.719 51.8982 288.33 42.9683C291.509 39.258 294.207 20.7064 281.779 13.1599C269.929 5.99084 234.379 2.02897 210.968 2.28051C168.193 2.65784 132.932 1.21144 88.133 2.65784C53.8356 3.7898 30.5211 3.16093 13.0833 12.3424C0.944344 18.7569 -0.115407 31.2085 4.60531 38.8178C11.9272 50.5147 36.0125 57.5581 72.8148 59.7591L83.1233 60.7653Z"
                                fill="white" stroke="black" strokeWidth="3" strokeMiterlimit="10"/>
                        </svg>

                        <input value={phrase} onChange={e => setPhrase(e.target.value)} type="password"/>
                    </div>
                    <input style={{backgroundImage: `url(/images/misc/login-btn.svg)`}} className="submit-btn"
                           type="submit" value="Logg inn"/>
                </form>
                <div className="logoWrapper">
                    <img src={forskningsraadet} alt=""/>
                    <img src={laeringsmiljosentet} alt=""/>
                </div>
            </div>

        </section>
    )
}

export default Home
