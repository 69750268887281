import React from 'react';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import AtlarsMusic from "../page-content/Atlars-music"
import './Atlars.scss';
import {ReactComponent as AtlarsIllustration} from "../../images/categories/atlars.svg";
import {atlars} from "../../content";

const Atlars = () => (
    <section className="atlars page">
        <div className="content-wrapper">
            <Backbutton linkUrl="/valg" title="Forsiden"/>
            <div className="top-image-wrapper">
                <AtlarsIllustration/>
            </div>
            <PageIntro soundfile={atlars.pageIntroAudio}>
                <p>{atlars.pageIntro}</p>
            </PageIntro>
            <ul>
                {atlars.audioTracks.map((item, index) => (
                    <li key={index}>
                        <AtlarsMusic key={index} content={item}/>
                    </li>
                ))}
            </ul>
            <h2>Musikkvideo</h2>
            <div className="videoWrapper">
                <iframe title="vimeo-player" src="https://player.vimeo.com/video/543545484" width="640" height="360"
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    </section>
)

export default Atlars
