import React from 'react';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import ListenToText from "../page-content/Listen-to-text"

import TextList from "../page-content/Text-list";
import './Conversation-list.scss'
import {conversationList} from "../../content";




const ConversationList = () => {

    return (
        <section className="conversation-list page">
            <div className="content-wrapper">
                <Backbutton color="dark" linkUrl="/sosiale-relasjoner" title="Sosiale relasjoner"    />
                <h1>{conversationList.title}</h1>
                <PageIntro soundfile={conversationList.pageIntroAudio}>
                    <p>{conversationList.pageIntro}</p>
                </PageIntro>
                <div className="intermission-image">
                    {conversationList.svg || <img src={conversationList.image} alt={conversationList.title}/>}
                </div>
                <ListenToText svgColor="dark" soundfile={conversationList.listAudio}/>
                <TextList listItems={conversationList.list}/>
            </div>
        </section>
    )
}

export default ConversationList
