import React from 'react';
import './Nav-screen.scss';

import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import BreathingExcersise from "../page-content/Breathing-excersise"

import {attentionTraining} from "../../content";
import {ReactComponent as OppmerksomhetsTrening} from "../../images/categories/oppmerksomhets-trening.svg";


const AttentionTraining = () => (
    <section className="attention-training page">
        <div className="content-wrapper">
            <Backbutton linkUrl="/valg" title="Forsiden"/>
            <div className="top-image-wrapper">
                <OppmerksomhetsTrening/>
            </div>
            <PageIntro soundfile={attentionTraining.pageIntroAudio}>
                <p>{attentionTraining.pageIntro}</p>
            </PageIntro>
            {attentionTraining.excersises.map((item, index) => (

                <BreathingExcersise key={index} content={item}/>
            ))}
        </div>
    </section>
)

export default AttentionTraining
