import React from 'react';
import './Feelings.scss';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import SubNav from "../page-content/Sub-nav"
import {ReactComponent as Folelser } from "../../images/categories/folelser.svg";

import {feelings} from "../../content";




const Feelings = () => {

    return (
        <section className="feelings page">
            <div className="content-wrapper">
                <Backbutton linkUrl="/valg" title="Forsiden"    />
                <div className="top-image-wrapper">
                    <Folelser />
                </div>
                <PageIntro soundfile={feelings.pageIntroAudio}>
                    <p>{feelings.pageIntro}</p>
                </PageIntro>
                <SubNav navItems={feelings.navText} />
            </div>
        </section>
    )
}

export default Feelings
