import React from 'react';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import Collapse from "../page-content/Collapse-content"
import './Threestep-model.scss'

import { threeStepModel} from "../../content";

const ThreeStepModel = () => {
    return (
        <section className="three-step-model page">
            <div className="content-wrapper">
                <Backbutton linkUrl="/problemlosning" title="Problemløsing"    />
                <h1>{threeStepModel.title}</h1>
                <PageIntro soundfile={threeStepModel.pageIntroAudio}>
                    <p>{threeStepModel.pageIntro}</p>
                </PageIntro>
                <div className="image-row">
                    <div className="image-wrapper">
                        <img src="/images/misc/stop.png" alt=""/>
                        <p>Hva er problemet?</p>
                    </div>
                    <div className="image-wrapper">
                        <img src="/images/misc/tenk.png" alt=""/>
                        <p>Valg og konsekvenser</p>
                    </div>
                    <div className="image-wrapper">
                        <img src="/images/misc/gjor.png" alt=""/>
                        <p>og sjekk ut virkning</p>
                    </div>
                </div>
                <h2 className="sound-heading">Slik bruker du tretrinnsmodellen</h2>
                <Collapse listItems={threeStepModel.collapseContent} soundfile={threeStepModel.audioManuscript} />
            </div>
        </section>
    )
}

export default ThreeStepModel;
