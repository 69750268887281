import React from 'react';

import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import './Thought-list.scss'
import TextList from "../page-content/Text-list";

import { learningList} from "../../content";
import ListenToText from "../page-content/Listen-to-text";




const ThoughtList = () => {

    return (
        <section className="thought-list page">
            <div className="content-wrapper">
                <Backbutton color="dark" linkUrl="/laerende-tankesett" title="Lærende tankesett"    />
                <h1>{learningList.title}</h1>
                <PageIntro soundfile={learningList.pageIntroAudio}>
                    <p>{learningList.pageIntro}</p>
                </PageIntro>
                <div className="intermission-image">
                    <img src={learningList.image} alt=""/>
                </div>
                <ListenToText svgColor="dark" soundfile={learningList.listAudio}/>

                <TextList listItems={learningList.list}/>
            </div>
        </section>
    )
}

export default ThoughtList
