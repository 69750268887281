import React from 'react';
import './Nav-screen.scss';
import {Link} from "react-router-dom";

import {ReactComponent as SosialeRelasjoner } from "../../images/categories/sosiale-relasjoner.svg";
import {ReactComponent as OppmerksomhetsTrening } from "../../images/categories/oppmerksomhets-trening.svg";
import {ReactComponent as Problemlosning } from "../../images/categories/problemlosning.svg";
import {ReactComponent as Folelser } from "../../images/categories/folelser.svg";
import {ReactComponent as LaerendeTankesett } from "../../images/categories/laerende-tankesett.svg";
import {ReactComponent as Atlars } from "../../images/categories/atlars.svg";
import {ReactComponent as Logo } from "../../images/logos/robust-logo.svg";


const NavScreen = () => {
    const categories = [
        {
            path: '/sosiale-relasjoner',
            imgPath :'/images/categories/sosiale-relasjoner.svg',
            svg: <SosialeRelasjoner />,
            name: 'Sosiale relasjoner'
        },
        {
            path: '/oppmerksomhets-trening',
            imgPath : '/images/categories/oppmerksomhets-trening.svg',
            svg: <OppmerksomhetsTrening />,
            name: 'Oppmerksomhetstrening'
        },
        {
            path: '/problemlosning',
            imgPath : '/images/categories/problemlosning.svg',
            svg: <Problemlosning />,
            name: 'Problemløsing'
        },
        {
            path: '/folelser',
            imgPath : '/images/categories/folelser.svg',
            svg: <Folelser />,
            name: 'Følelser'
        },
        {
            path: '/laerende-tankesett',
            imgPath : '/images/categories/laerende-tankesett.svg',
            svg: <LaerendeTankesett />,
            name: 'Lærende tankesett'
        },
        {
            path: '/atlars',
            imgPath : '/images/categories/atlars.svg',
            svg: <Atlars />,
            name: 'ATLARS'
        },
    ]

    const categoryItems = categories.map((category,index) =>
        <li key={index}>
            <Link to={category.path}>
                {category.svg}
            </Link>
        </li>
    );


    return (
        <section className="nav-screen page">
            <div className="content-wrapper">
                <Logo/>
                <ul>
                    {categoryItems}
                </ul>
            </div>
        </section>
    )
}

export default NavScreen
