import './index.scss';
import './App.scss';
import Home from "./components/general/Home";
import NavScreen from "./components/general/Nav-screen";
import SocialRelations from "./components/general/Social-relations";
import Feelings from "./components/general/Feelings";
import LearningMindset from "./components/general/Learning-mindset";
import Problemsolving from "./components/general/Problemsolving";
import AttentionTraining from "./components/general/Attention-training";
import Atlars from "./components/general/Atlars";
import RelationMap from "./components/sub-pages/Relation-map";
import ConversationList from "./components/sub-pages/Conversation-list";
import ThreeStepModel from "./components/sub-pages/Threestep-model";
import Laddermodel from "./components/sub-pages/Laddermodel";
import StressScale from "./components/sub-pages/Stress-scale";
import ThoughtScale from "./components/sub-pages/Thought-scale";
import InterpretationModel from "./components/sub-pages/Interpretation-model";
import ThoughtLearnings from "./components/general/Thought-learnings";
import ThoughtList from "./components/sub-pages/Thought-list";
import {Route, Switch, useHistory, useLocation,} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import CareList from "./components/sub-pages/Care-list";

export const AudioContext = React.createContext({audio: null, url: null, stop: null, location: null});

const App = () => {
    const audioContext = useContext(AudioContext);
    const location = useLocation();
    const data = localStorage.getItem("authorized");
    const history = useHistory()

    useEffect(() => {
        if (location.pathname !== audioContext.location && audioContext.audio) {
            audioContext.audio.pause();
            audioContext.stop();
            audioContext.audio.removeEventListener('ended', audioContext.stop);
        }
    }, [location, audioContext, audioContext.location])

    useEffect(() => {
        if (data !== 'yes' && location.pathname!=="/") {
            history.push("/")
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (

        <div className="App">
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/valg" component={NavScreen}/>
                <Route exact path="/sosiale-relasjoner" component={SocialRelations}/>
                <Route exact path="/folelser" component={Feelings}/>
                <Route exact path="/laerende-tankesett" component={LearningMindset}/>
                <Route exact path="/problemlosning" component={Problemsolving}/>
                <Route exact path="/oppmerksomhets-trening" component={AttentionTraining}/>
                <Route exact path="/atlars" component={Atlars}/>
                <Route exact path="/relasjonskart" component={RelationMap}/>
                <Route exact path="/huskeliste-for-aa-starte-en-samtale" component={ConversationList}/>
                <Route exact path="/ta-vare-paa-hverandre-plakaten" component={CareList}/>
                <Route exact path="/tretrinnsmodellen" component={ThreeStepModel}/>
                <Route exact path="/stige-modellen" component={Laddermodel}/>
                <Route exact path="/stressskalaen" component={StressScale}/>
                <Route exact path="/tenkeskalaen" component={ThoughtScale}/>
                <Route exact path="/tolkningsmodellen" component={InterpretationModel}/>
                <Route exact path="/hvordan-tenke-rundt-laering" component={ThoughtLearnings}/>
                <Route exact path="/huskeliste-for-laerende-tankesett" component={ThoughtList}/>
            </Switch>
        </div>


    )
}

export default App;
